
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
@Component({})
export default class Breadcrumb extends Vue {
  @Prop({ type: Boolean })
  showBottomBorder: boolean;
  @Prop({
    default: () => [
      {
        name: "首页",
        path: "/"
      }
    ]
  })
  itemData: [];
}
